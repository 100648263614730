<template>
	<div class="financePage">
		<el-card>
			<!-- 搜索区域 -->
			<div class="searchArea">
				<el-select v-model="selectForm.corpId" placeholder="请选择所属机构" filterable clearable @change="selectChange"
					@clear="search">
					<el-option v-for="(item, index) in orderCompanyList" :key="index" :label="item.companyName"
						:value="item.uuid"></el-option>
				</el-select>
				<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
			</div>
			<!-- 按钮区域 -->
			<!-- 	<div class="btnsArea">
				<el-button type="warning" size="mini" icon="el-icon-star-off" @click="enterpriseSure()">企业确认</el-button>
				<el-button type="success" size="mini" icon="el-icon-star-off" @click="auditSure()">审核确认</el-button>
				<el-button type="danger" size="mini" icon="el-icon-star-off" @click="financeSure()">财务确认</el-button>
			</div> -->
			<!-- 表格区域 -->
			<div class="tableArea" style="margin-top: 20px;">
				<el-table ref="tableList" :data="tableList" tooltip-effect="dark" style="width: 100%"
					highlight-current-row stripe border :header-cell-style="rowOrderClass"
					@current-change="handleCurrentChange">
					<el-table-column prop="packetId" label="结算编号" align="center"></el-table-column>
					<el-table-column prop="companyName" label="机构名称" align="center">
					</el-table-column>
					<el-table-column prop="settlementTime" label="结算时段" align="center"></el-table-column>
					<el-table-column prop="settlementMoney" label="结算金额" align="center"></el-table-column>
					<el-table-column prop="applyTime" label="申请时间" align="center"></el-table-column>
					<el-table-column prop="applyUser" label="申请人" align="center"></el-table-column>
					<el-table-column prop="status" label="状态" align="center">
						<!-- <template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.status == 1)">经办</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status == 2">已完成</el-tag>
							<el-tag effect="dark" type="danger" v-else-if="scope.row.status == 3">已撤销</el-tag>
							<el-tag effect="dark" type="warning" v-else-if="scope.row.status == 4">审核失败</el-tag>
						</template> -->
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.status === 1">{{ "经办" }}</el-tag>
							<el-tag effect="dark" type="success" v-else-if="scope.row.status === 2">{{ "已完成" }}
							</el-tag>
							<el-tag effect="dark" type="danger" v-else-if="scope.row.status === 3">{{ "已撤销" }}
							</el-tag>
							<el-tag effect="dark" type="warning" v-else-if="scope.row.status === 4">{{ "审核失败" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="操作" align="center">
						<template slot-scope="scope">
							<!-- <el-button v-if="userName == '黄玉玲'" size="mini" type="primary" @click="">审核</el-button> -->
							<el-button size="mini" type="primary" @click="checkClick(scope.row)">查看</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 财务确认弹出框 -->
			<el-dialog :visible.sync="liqDialogFormVisible" class="liq-dialog">
				<el-form :model="liqForm" inline ref="liqForm">
					<el-row>
						<el-form-item label="发票">
							<el-input v-model="liqForm.userName" autocomplete="off"></el-input>
						</el-form-item>
					</el-row>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" @click="liqDialogFormVisible = false">取 消</el-button>
					<el-button type="primary" @click="liqDialogFormVisible = false">确 定</el-button>
				</div>
			</el-dialog>

			<!-- 查看 -->
			<el-dialog :visible.sync="viewVisible" width="70%">
				<el-table :data="viewTableData" style="width: 100%" tooltip-effect="dark" highlight-current-row stripe
					border :header-cell-style="rowOrderClass" max-height="500">
					<el-table-column prop="orderNo" label="订单编号" align="center"></el-table-column>
					<el-table-column prop="payNo" label="第三方订单流水号" align="center"></el-table-column>
					<el-table-column prop="payTime" label="订单时间" align="center"></el-table-column>
					<el-table-column prop="payChannel" label="订单来源" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.payChannel === '1'">{{ "微信" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '2'">{{ "支付宝" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '3'">{{ "帐户余额" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payChannel === '4'">{{ "二维码" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="memberName" label="下单用户" align="center"></el-table-column>
					<el-table-column prop="payType" label="支付类型" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.payType === '0'">{{ "预付" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.payType === '1'">{{ "完成支付" }}
							</el-tag>
                            <el-tag effect="dark" v-else-if="scope.row.payType === '2'">{{ "补充收款" }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="isRefund" label="是否退款" align="center">
						<template slot-scope="scope">
							<el-tag effect="dark" v-if="scope.row.isRefund === 0">{{ "未退款" }}</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 1">{{ "已退款" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 2">{{ "已申请" }}
							</el-tag>
							<el-tag effect="dark" v-else-if="scope.row.isRefund === 3">{{ "退款失败" }}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="payFee" label="订单金额" align="center"></el-table-column>
                    <el-table-column prop="carNum" label="车牌号" align="center"></el-table-column>
                    <el-table-column prop="startAddress" label="起始地" align="center"></el-table-column>
                    <el-table-column prop="endAddress" label="目的地" align="center"></el-table-column>
                    <el-table-column prop="memberName" label="会员姓名" align="center"></el-table-column>
                    <el-table-column prop="memberMobile" label="会员手机号" align="center"></el-table-column>
                </el-table>

				<el-row :gutter="20" style="margin-top: 20px;">
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">总金额: </div>
							<el-input v-model="viewform.settlementMoney" disabled>
							</el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">确定金额: </div>
							<el-input v-model="viewform.settlementFixMoney" disabled>
							</el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div style="display: flex; white-space: nowrap; line-height: 30px;">
							<div style="margin-right: 10px; color: #2196fb;">申请月份: </div>
							<el-date-picker v-model="viewform.applyTime" type="month" placeholder="结束时间"
								value-format="yyyy-MM" disabled />
						</div>
					</el-col>
				</el-row>

				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="viewVisible = false">确 定</el-button>
				</span>
			</el-dialog>

		</el-card>
	</div>
</template>

<script>
import _ from 'lodash'
import store from '@/store/index.js'

export default {
  name: 'financeManage',
  components: {},
  data () {
    return {
      selectForm: {
        date: '',
        startTime: '',
        endTime: '',
        corpId: ''
      }, // 搜索表单
      orderCompanyList: [], // 机构列表
      tableList: [], // 表格数据源
      liqDialogFormVisible: false,
      liqForm: {},
      clickform: {},
      userName: '',
      viewVisible: false, // 查看详情dialog
      viewTableData: [], // 详情Table
      viewform: {
        settlementMoney: '', // 总金额
        settlementFixMoney: '', // 确定金额
        applyTime: '' // 申请月份
      },
      companyId: ''
    }
  },
  created () {
    this.userName = window.sessionStorage.getItem('userName')
    this.companyId = window.sessionStorage.getItem('companyId')
    this.getOrderTable()
    this.getorderCompany()
  },
  mounted () {

  },
  methods: {
    // 给订单表格头部设定背景色
    rowOrderClass ({
      row,
      rowIndex
    }) {
      return 'background:#2196fb;color:#fff'
    },
    // 切换选中表格项时触发
    handleCurrentChange (val) {
      if (!val) return
      this.clickform = _.cloneDeep(val)
    },
    async getorderCompany (e) {
      const {
        data: res
      } = await this.$http.post('/userManageServer/tSysCompany/selectCompanyDataAuthScope',
        {
          headers: {
            uuid: store.state.order_company || sessionStorage.getItem('order_company'),
            AUTH_TYPE: 'company'
          }
        })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.orderCompanyList = res.data
    },
    // 获取列表
    async getOrderTable () {
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/getTInfoOrderFeeList', {
        pageNo: 1,
        pageSize: 20,
        statuss: ['1', '2', '3', '4'],
        // companyId: this.userName == '黄玉玲' ? '' : this.companyId,
        companyId: this.companyId
        // corpId: this.selectForm.corpId
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取列表失败')
      this.tableList = res.data.records
      console.log(res)
      console.log(this.tableList, 'tableList')
    },
    // 点击搜索按钮触发
    search () {
      console.log(this.selectForm.corpId)
      if (!this.selectForm.corpId) {
        this.companyId = ''
      }
      this.getOrderTable()
    },
    // STATUS` char(2) DEFAULT NULL COMMENT '结算状态(0：新增，1：企业确认，2：审核确认，3：财务完成，4：撤回，5：删除)'
    // 点击企业确认触发
    async enterpriseSure () {
      if (JSON.stringify(this.clickform) == '{}') return this.$message.error('请先选择需要审核的项')
      if (this.clickform.status === '1') return this.$message.error('已经通过企业确认')
      this.clickform.status = '1'
      const {
        data: res
      } = await this.$http.post('/billService/tBillClearRecord/updateTBillClearRecordStatus', JSON.parse(JSON
        .stringify(this.clickform)))
      this.clickform = {}
      this.getOrderTable()
    },
    // 点击审核确认触发
    async auditSure () {
      if (JSON.stringify(this.clickform) == '{}') return this.$message.error('请先选择需要审核的项')
      if (this.clickform.status === '2') return this.$message.error('已经通过审核确认')
      this.clickform.status = '2'
      const {
        data: res
      } = await this.$http.post('/billService/tBillClearRecord/updateTBillClearRecordStatus', JSON.parse(JSON
        .stringify(this.clickform)))
      this.clickform = {}
      this.getOrderTable()
    },
    // 点击财务确认触发
    async financeSure () {
      if (JSON.stringify(this.clickform) == '{}') return this.$message.error('请先选择需要审核的项')
      this.liqDialogFormVisible = true
      // this.clickform.status = '3'
      // const { data: res } = await this.$http.post('/billService/tBillClearRecord/updateTBillClearRecordStatus', {})
      // console.log(res);
      // this.clickform
    },
    // 点击财务确认--确认按钮触发
    async financeSured () {
      // if() return this.$message.error('请先输入发票')
      this.clickform.status = '3'
      const {
        data: res
      } = await this.$http.post('/billService/tBillClearRecord/updateTBillClearRecordStatus', JSON.parse(JSON
        .stringify(this.clickform)))
      this.clickform = {}
      this.liqDialogFormVisible = false
      this.getOrderTable()
    },
    async checkClick (row) { // 查看详情
      console.log(row)
      this.viewform.settlementMoney = row.settlementMoney
      this.viewform.settlementFixMoney = row.settlementFixMoney
      this.viewform.applyTime = row.settlementTime
      const {
        data: res
      } = await this.$http.post('/billService/InfoPacketController/getTInfoPacketItme', {
        packetId: row.packetId
      })
      if (res.statusCode !== '200') {
        return this.$message.error(res.resultDesc)
      }
      this.viewTableData = res.data
      this.viewVisible = true
      console.log(res, '查看详情')
    },
    selectChange (value) {
      this.selectForm.corpId = value
      this.companyId = value
    }
  }
}
</script>
<style lang="less" scoped>
	.financePage {
		width: 100%;
		height: 100%;

		/deep/ .el-card__body {
			padding: 10px;

			.searchArea {
				display: flex;
				align-items: center;

				// justify-content: center;
				.el-range-editor {
					margin-left: 20px;

					.el-range-separator {
						padding: 0;
					}
				}

				.el-select {
					margin: 0 20px;
				}
			}

			/deep/ .el-table {
				margin-bottom: 20px;

				.el-table__row--striped td {
					background-color: #e4eaec !important;
				}

				.current-row>td {
					background-color: #66b1ff !important;
				}
			}

			.btnsArea {
				margin: 20px 0 15px;
				display: flex;
				justify-content: flex-end;
			}

			.liq-dialog {
				.dialog-Title {
					color: #0097fe;
					font-size: 20px;
					font-weight: 700;
				}

				/deep/ .el-form {
					.subtitle {
						color: #0097fe;
						font-size: 14px;
						font-weight: 700;
						margin-bottom: 20px;
						padding-left: 6px;
						border-left: 5px solid #0097fe;
					}

					.el-row {
						display: flex;

						.el-form-item {
							flex: 1;
							display: flex;

							.el-form-item__content {
								flex: 1;

								.el-date-editor {
									width: 100%;
								}

								.el-input {
									width: 100%;

									.el-input__inner {
										width: 100%;
									}
								}

								.el-select {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
